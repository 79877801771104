<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-group
          deck
          class="padding-bottom-normal"
        >
          <EditLocationForm
            :initial-form-data="location"
            :on-form-saved="onLocationSaved"
            :on-location-changed="onLocationDetailsChanged"
          />
          <b-card
            key="{{ location.id }}"
            class="d-none d-md-block"
            md="6"
            :img-src="require('@/assets/images/people5.png')"
          >
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <b-media vertical-align="top">
                    <template #aside>
                      <b-avatar
                        variant="light-primary"
                        square
                        size="lg"
                      >
                        <feather-icon icon="HomeIcon" />
                      </b-avatar>
                    </template>
                    <h4 class="media-heading">
                      {{ location.name }}
                    </h4>
                    <b-card-text class="mb-0">
                      Address: {{ location.address }}
                    </b-card-text>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row
      md="6"
    >
      <b-col xl="6">
        <b-row>
          <b-col
            v-if="location"
            xs="12"
            lg="6"
            xl="12"
          >
            <b-card>
              <b-card-header>
                <h3>Recurring Services</h3>
              </b-card-header>
              <b-card-body class="padding-top-only">
                <div class="table">
                  <table
                    class="table b-table table-stripped table-hover"
                  >
                    <tbody>
                      <template v-for="event in location.recurring_events">
                        <tr
                          :key="event.id"
                          @click="tableRowClicked({ name: 'edit-recurring-event', params: { locationId: location.id, recurringEventId: event.id }})"
                        >
                          <td class="recurring-icon">
                            <span>
                              <feather-icon
                                icon="RotateCwIcon"
                                size="16"
                              />

                            </span>
                          </td>
                          <td>
                            <div class="schedule-row schedule-row__alt">
                              <div class="schedule-column-date">
                                <b-badge
                                  variant="primary inverted-badge"
                                  style="width:100%"
                                >
                                  <feather-icon
                                    icon="CalendarIcon"
                                    class="mr-25"
                                  />
                                  <span>{{ convertDOWToString(event.day_of_week) }}, {{ event.time|formatTime }} </span>
                                </b-badge>
                              </div>
                              <div class="schedule-column-name">
                                <span>
                                  {{ event.name }}
                                </span>
                              </div>
                            </div>

                          </td>
                          <td style="text-align:right;">
                            <b-button
                              variant="outline-primary"
                              class="btn-icon mr-0 mr-md-1"
                              :to="{ name: 'edit-recurring-event', params: { locationId: location.id, recurringEventId: event.id }}"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
              <b-card-footer class="padding-top-only no-bottom-padding justify-content-end d-flex">
                <b-button
                  :to="{name: 'new-recurring-event'}"
                  variant="primary"
                >
                  Add recurring service
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>
          <b-col
            v-if="location"
            xs="12"
            lg="6"
            xl="12"
          >
            <b-card>
              <b-card-header>
                <h3>Single Services</h3>
              </b-card-header>
              <b-card-body class="padding-top-only">
                <div class="table-responsive">
                  <table
                    class="table b-table table-stripped table-hover"
                  >
                    <tbody>
                      <template v-for="event in location.single_events">
                        <tr
                          :key="event.id"
                          @click="tableRowClicked({ name: 'edit-single-event', params: { locationId: location.id, singleEventId: event.id }})"
                        >
                          <td class="recurring-icon">
                            <span>
                              <feather-icon
                                icon="RotateCwIcon"
                                size="16"
                              />

                            </span>
                          </td>
                          <td>
                            <div class="schedule-row schedule-row__alt">
                              <div class="schedule-column-date">
                                <b-badge
                                  variant="primary inverted-badge"
                                  class="mr-25"
                                  style="width:100%"
                                >
                                  <feather-icon
                                    icon="CalendarIcon"
                                    class="mr-25"
                                  />
                                  <span>{{ event.date | formatDate }}, {{ event.time|formatTime }} </span>
                                </b-badge>
                              </div>
                              <div class="schedule-column-name">
                                <span>
                                  {{ event.name }}
                                </span>
                              </div>
                            </div>

                          </td>
                          <td style="text-align:right;">
                            <b-button
                              variant="outline-primary"
                              class="btn-icon mr-0 mr-md-1"
                              :to="{ name: 'edit-single-event', params: { locationId: location.id, singleEventId: event.id }}"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
              <b-card-footer class="padding-top-only no-bottom-padding justify-content-end d-flex">
                <b-button
                  :to="{name: 'new-single-event'}"
                  variant="primary"
                >
                  Add single service
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
      <b-col xl="6">
        <div v-if="location">
          <div v-if="schedule">
            <b-card>
              <b-card-header>
                <h3>Schedule</h3>
              </b-card-header>
              <b-card-body class="padding-top-only">
                <div class="table">
                  <table
                    :key="componentKey"
                    class="table b-table"
                  >
                    <tbody>
                      <tr v-for="eventInstance in schedule">
                        <template v-if="eventInstance.type === 'recurring'">
                          <td class=" recurring-icon">
                            <feather-icon
                              icon="RotateCwIcon"
                              size="16"
                            />
                          </td>
                          <td colspan="2">
                            <div class="schedule-row">
                              <div class="schedule-column-date">
                                <b-badge
                                  variant="primary"
                                  class="inverted-badge w-100"
                                >
                                  <feather-icon
                                    icon="CalendarIcon"
                                    class="mr-25"
                                  />
                                  <span>{{ eventInstance.date|formatDate }}, {{ eventInstance.time|formatTime }}</span>
                                </b-badge>
                              </div>
                              <div class="schedule-column-name">
                                <span>
                                  {{ eventInstance.name }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            style="text-align:right"
                            class=""
                          >
                            <b-form-checkbox
                              :checked="!eventInstance.is_cancelled"
                              name="check-button"
                              switch
                              inline
                              style="margin-right:0;"
                              @change="changeEventCancelledState($event, eventInstance)"
                            />
                          </td>
                        </template>
                        <template v-else>
                          <td class=" single-icon">
                            <span>
                              <feather-icon
                                icon="StarIcon"
                                size="16"
                              />
                            </span>
                          </td>
                          <td colspan="2">
                            <div class="schedule-row">
                              <div class="schedule-column-date">
                                <b-badge
                                  variant="primary"
                                  class="inverted-badge w-100"
                                >
                                  <feather-icon
                                    icon="CalendarIcon"
                                    class="mr-25"
                                  />
                                  <span>{{ eventInstance.date|formatDate }}, {{ eventInstance.time|formatTime }}</span>
                                </b-badge>
                              </div>
                              <div class="schedule-column-name">
                                <span>
                                  {{ eventInstance.name }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="" />
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-button
      variant="outline-danger"
      :disabled="isSubmitting"
      @click="deleteLocation"
    >
      Delete
    </b-button>
  </div>

</template>
<script>
import EditLocationForm from '@/views/pages/locations/EditLocationForm'
import store from '@/store'
import { cloneDeep } from 'lodash'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCardHeader,
  BCardText,
  BCol,
  BFormCheckbox,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BRow,
  BModal,
} from 'bootstrap-vue'
import AccountService from '@/services/AccountService'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import dayjs from 'dayjs'

export default {
  components: {
    EditLocationForm,
    BButton,
    BLink,
    BCol,
    BRow,
    BFormCheckbox,
    BCardBody,
    BCard,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    BCardGroup,
    BAvatar,
    BMedia,
    BBadge,
    BCardHeader,
    BCardText,
    BModal,
  },
  props: [
    'locationId',
  ],
  data() {
    return {
      isSubmitting: false,
      location: cloneDeep(store.getters['auth/locationById'](this.locationId)),
      schedule: null,
      componentKey: 0,
    }
  },
  created() {
    this.refreshSchedule()
  },
  methods: {
    async getNumberOfLocationServicesVisitors() {
      return AccountService.getLocationServicesVisitorsCount(this.locationId)
    },
    async showConfirmDeleteModal() {
      const res = await this.getNumberOfLocationServicesVisitors()
      let message = 'Are you sure you want to delete this location?'
      if (res && res.length > 0) {
        message = `Are you sure you want to delete? There are ${res.length} visitors scheduled for this location, their data will be preserved in contacts`
      }
      return this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
    deleteLocation() {
      this.showConfirmDeleteModal()
        .then(async value => {
          if (value) {
            this.isSubmitting = true
            try {
              await AccountService.deleteLocation(this.locationId)
              // reload list of locations after deletion
              await store.dispatch('auth/getAuthUser')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Location deleted successfully',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'locations' })
            } catch (error) {
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          } else {
            this.componentKey += 1
          }
        })
    },
    onLocationDetailsChanged(locationData) {
      this.location = locationData
    },
    async showConfirmCancellationModal(event, eventInstance) {
      const formattedDate = dayjs(eventInstance.date).format('ddd, MMM YYYY')
      let message = `Are you sure you want to re-enable this service on ${formattedDate}`
      if (!eventInstance.is_cancelled) {
        const scheduledVisitors = await AccountService.getScheduledVisitorsForRecurringEvent(
          eventInstance.recurring_event_id,
          eventInstance.date,
        ).then(response => response.data.data)
        if (scheduledVisitors.length) {
          message = `The service you are about to cancel on ${formattedDate} has ${scheduledVisitors.length} visits planned`
        } else {
          message = `The service you are about to cancel on ${formattedDate} has no visits planned`
        }
        return this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
      }
      return this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
    changeEventCancelledState(event, eventInstance) {
      const showConfirmationModal = this.showConfirmCancellationModal(event, eventInstance)
      showConfirmationModal.then(value => {
        if (value) {
          this.setCancelled(event, eventInstance)
        } else {
          this.componentKey += 1
        }
      })
    },
    tableRowClicked(record) {
      router.push(record)
    },
    onLocationSaved() {

    },
    convertDOWToString(dow) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return days[dow]
    },
    refreshSchedule() {
      const self = this
      AccountService.getScheduleForLocation(this.locationId)
        .then(apiResponse => {
          self.schedule = apiResponse.data.data
        })
    },
    showScheduleChangeNotification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Updated successfully',
          variant: 'success',
        },
      })
    },
    async setCancelled(checked, eventInstance) {
      this.isSubmitting = true
      try {
        if (!checked) {
          // check if there are any visitors scheduled for this event
          const scheduledVisitors = await AccountService.getScheduledVisitorsForRecurringEvent(
            eventInstance.recurring_event_id,
            eventInstance.date,
          ).then(response => response.data.data)

          if (scheduledVisitors.length) {
            // ok we've got at least one visitor scheduled
            await this.$router.push({
              name: 'add-recurring-event-exception',
              params: {
                locationId: this.locationId,
                recurringEventId: eventInstance.recurring_event_id,
                recurringEventDate: eventInstance.date,
              },
            })
            // show the whole wizard etc. When done, call:
            // AccountService.createRecurringEventException(eventInstance.recurring_event_id, eventInstance.date)
            // to actually create an exception
          } else {
            // create a recurring event exception for this date

            await AccountService.createRecurringEventException({
              recurring_event_id: eventInstance.recurring_event_id,
              date: eventInstance.date,
            }).then(() => {
              this.refreshSchedule()
              this.showScheduleChangeNotification()
              this.isSubmitting = false
            })
          }
        } else {
          // remove recurring event exception for this date
          await AccountService.deleteRecurringEventException(eventInstance.recurring_event_exception_id).then(() => {
            this.refreshSchedule()
            this.showScheduleChangeNotification()
            this.isSubmitting = false
          })
        }
      } catch (error) {
        this.isSubmitting = false
      }
    },
  },
}
</script>
<style scoped>
[dir] .padding-top-only {
  padding: 1.5rem 0 !important;
}

[dir] .card-body.padding-top-only {
  padding: 1.5rem 0;
}

[dir] .card-deck.padding-bottom-normal {
  padding-bottom: 1.5rem;
}

[dir] .no-padding {
  padding: 0 !important;
}

[dir] .no-bottom-padding {
  padding-bottom: 0 !important;
}

.color-white {
  color: #FFF;
}

.bg-primary.bg-lighten-3:hover {
  background-color: #a49fe2 !important;
  color: #FFF;
}

.recurring-icon, .single-icon {
  border-right: 1px solid #F0F0F0;
  text-align: center;
  width: 50px;
}
.card .card-img{
  margin-top:-1px;
  background-color: #f5f5f5;
}
.dark-layout .card .card-img{
  background-color: #131821;
}

.inverted-badge {
  background: transparent;
  color: var(--purple);
  border: 1px solid var(--purple);
  padding: 0.75rem;
}

.table td {
  padding: 1rem 2rem;
}

.schedule-row {
  display: grid;
  grid-template: 1fr / 175px auto;
  grid-gap: 10px 30px;
  justify-items: flex-start;
  align-items: center;
}

.schedule-column-date {
  min-width: 175px;
}

.table tbody tr:first-child td {
  border-top: 0;
}

@media (max-width: 1500px) {
  .table td {
    padding: 0.72rem 1rem;
  }
}

@media (max-width: 1400px) {
  .schedule-row {
    grid-template: auto auto / 1fr;
    text-align: center;
  }
}

@media (max-width: 1199px) {
  .schedule-row {
    grid-template: 1fr / 175px auto;
    text-align: left;
  }

  .schedule-row__alt {
    grid-template: auto auto / 1fr;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .schedule-row, .schedule-row__alt {
    grid-template: 1fr / 175px auto;
    text-align: left;
  }
}

@media (max-width: 640px) {
  .schedule-row, .schedule-row__alt {
    grid-template: auto auto / 1fr;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .table td {
    padding: 0.72rem 0.5rem;
  }

  .schedule-row, .schedule-row__alt {
    font-size: 82%;
  }

  .schedule-column-date {
    min-width: 148px;
  }
}
</style>
